/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import {
    FrontendApi,
    Configuration,
    LoginFlow,
    RegistrationFlow,
    VerificationFlow,
    UpdateLoginFlowBody,
    UpdateRegistrationFlowBody,
    UpdateVerificationFlowBody,
    SuccessfulNativeLogin,
    SuccessfulNativeRegistration,
    RecoveryFlow,
    UpdateRecoveryFlowBody,
    SettingsFlow,
    UpdateSettingsFlowBody,
} from '@ory/client'
import { AxiosError } from 'axios'
import Bottleneck from 'bottleneck'
import { ErrorSwal } from 'vspry-style-components'

import { translateContextless } from 'context/localeContext'
import AuthInterface, { AuthIdentity, Tenant, Type } from '../interface'
import Flow from './components/Flow'

export type KratosFlow = LoginFlow | RegistrationFlow | VerificationFlow | RecoveryFlow | SettingsFlow

const TOKEN_EXPIRY = 1000 * 60 // 1m

const getClient = (tenant: Tenant) =>
    new FrontendApi(
        new Configuration({
            basePath: `${window.configuration['AUTH_URL']}/${tenant}`,
            baseOptions: {
                withCredentials: true,
            },
        })
    )

const getReturnTo = (redirect?: string) => (redirect ? `https://${window.location.host}/#${redirect}` : `https://${window.location.host}/#/`)
const limiter = new Bottleneck({ maxConcurrent: 1 })

export default class KratosAuthProvider extends AuthInterface {
    protected client!: FrontendApi
    #user: AuthIdentity | null
    #token: string | null
    #tokenExpires: number | null
    #publicToken: string | null
    #publicTokenExpires: number | null

    constructor(...args: ConstructorParameters<typeof AuthInterface>) {
        super(...args)
        this.#user = null
        this.#token = null
        this.#tokenExpires = null
        this.#publicToken = null
        this.#publicTokenExpires = null
    }

    #setToken(token: string | null) {
        this.#token = token
        if (token) this.#tokenExpires = Date.now() + TOKEN_EXPIRY
    }

    #setPublicToken(token: string | null) {
        this.#publicToken = token
        if (token) this.#publicTokenExpires = Date.now() + TOKEN_EXPIRY
    }

    #setUser(user: AuthIdentity | null) {
        this.#setToken(null)
        if (this.onChange) this.onChange(user)
        this.#user = user
    }

    override setTenant(tenant: Tenant) {
        super.setTenant(tenant)
        this.client = getClient(tenant)
    }

    async getUser() {
        if (this.#user) return this.#user
        try {
            if (!this.client) this.setTenant(this.getTenant())
            const response = await this.client.toSession()
            const { identity } = response.data
            const u = identity ? { email: identity.traits['email'] } : null
            this.#setUser(u)
            return u
        } catch (e) {
            this.#setUser(null)
            return null
        }
    }

    async #getIDToken(refresh?: boolean) {
        if (!refresh && this.#token && this.#tokenExpires && Date.now() < this.#tokenExpires) return this.#token
        try {
            const s = await this.client.toSession({ tokenizeAs: 'default' })
            const t = s.data.tokenized ?? null
            if (t) this.#setToken(t)
            return t
        } catch (e) {
            if (this.#token) {
                await ErrorSwal.fire({
                    title: translateContextless('swal.session.expired.title'),
                    text: translateContextless('swal.session.expired.text'),
                    showConfirmButton: true,
                    confirmButtonText: `Ok`,
                    timer: undefined,
                }).then(() => {
                    this.#setUser(null)
                    throw new Error('expired')
                })
            }
            return null
        }
    }

    async getIDToken(refresh?: boolean) {
        return limiter.schedule(() => this.#getIDToken(refresh))
    }

    async getIDTokenExpiry() {
        return this.#tokenExpires
    }

    async getPublicToken(refresh?: boolean) {
        if (!refresh && this.#publicToken && this.#publicTokenExpires && Date.now() < this.#publicTokenExpires) return this.#publicToken
        try {
            const s = await this.client.toSession({ tokenizeAs: 'public' })
            const t = s.data.tokenized ?? null
            if (t) this.#setPublicToken(t)
            return t
        } catch (e) {
            return null
        }
    }

    async getPublicTokenExpiry() {
        return this.#publicTokenExpires
    }

    async #getLoginFlow(flowID?: string, redirect?: string): Promise<LoginFlow> {
        return (flowID ? this.client.getLoginFlow({ id: flowID }) : this.client.createBrowserLoginFlow({ returnTo: getReturnTo(redirect) })).then(
            (d) => d.data
        )
    }

    async #getRegistrationFlow(flowID?: string, redirect?: string): Promise<RegistrationFlow> {
        return (
            flowID ? this.client.getRegistrationFlow({ id: flowID }) : this.client.createBrowserRegistrationFlow({ returnTo: getReturnTo(redirect) })
        ).then((d) => d.data)
    }

    async #getVerificationFlow(flowID?: string, redirect?: string): Promise<VerificationFlow> {
        return (
            flowID ? this.client.getVerificationFlow({ id: flowID }) : this.client.createBrowserVerificationFlow({ returnTo: getReturnTo(redirect) })
        ).then((d) => d.data)
    }

    async #getRecoveryFlow(flowID?: string, redirect?: string): Promise<RecoveryFlow> {
        return (
            flowID ? this.client.getRecoveryFlow({ id: flowID }) : this.client.createBrowserRecoveryFlow({ returnTo: getReturnTo(redirect) })
        ).then((d) => d.data)
    }

    async #getSettingsFlow(flowID?: string, redirect?: string): Promise<SettingsFlow> {
        return (
            flowID ? this.client.getSettingsFlow({ id: flowID }) : this.client.createBrowserSettingsFlow({ returnTo: getReturnTo(redirect) })
        ).then((d) => d.data)
    }

    async getFlow(type: Type, flowID?: string, redirect?: string): Promise<KratosFlow | undefined> {
        const handleFlowError = (e: unknown) => {
            if (e instanceof AxiosError && e.response?.data?.error?.id === 'session_already_available') {
                window.location.href = `${window.location.href.split('/#')[0]}/#${redirect ?? `/home`}`
            }
            return undefined
        }

        switch (type) {
            case 'login':
                return this.#getLoginFlow(flowID, redirect).catch(handleFlowError)
            case 'registration':
                return this.#getRegistrationFlow(flowID, redirect).catch(handleFlowError)
            case 'verification':
                return this.#getVerificationFlow(flowID, redirect).catch(handleFlowError)
            case 'recovery':
                return this.#getRecoveryFlow(flowID, redirect).catch(handleFlowError)
            case 'settings':
                return this.#getSettingsFlow(flowID, redirect).catch(handleFlowError)
            default:
                return undefined
        }
    }

    getFlowElement(type: Type): JSX.Element {
        return <Flow type={type} auth={this} />
    }

    async submitFlow(
        type: Type,
        flowID: string,
        body: UpdateLoginFlowBody | UpdateRegistrationFlowBody | UpdateVerificationFlowBody | UpdateRecoveryFlowBody | UpdateSettingsFlowBody
    ): Promise<SuccessfulNativeLogin | SuccessfulNativeRegistration | VerificationFlow | RecoveryFlow | SettingsFlow | undefined> {
        switch (type) {
            case 'login':
                return this.client.updateLoginFlow({ flow: flowID, updateLoginFlowBody: body as UpdateLoginFlowBody }).then((d) => d.data)
            case 'registration':
                return this.client
                    .updateRegistrationFlow({ flow: flowID, updateRegistrationFlowBody: body as UpdateRegistrationFlowBody })
                    .then((d) => d.data)
            case 'verification':
                return this.client
                    .updateVerificationFlow({ flow: flowID, updateVerificationFlowBody: body as UpdateVerificationFlowBody })
                    .then((d) => d.data)
            case 'recovery':
                return this.client.updateRecoveryFlow({ flow: flowID, updateRecoveryFlowBody: body as UpdateRecoveryFlowBody }).then((d) => d.data)
            case 'settings':
                return this.client.updateSettingsFlow({ flow: flowID, updateSettingsFlowBody: body as UpdateSettingsFlowBody }).then((d) => d.data)
            default:
                return undefined
        }
    }

    async getError(id: string) {
        return this.client.getFlowError({ id }).then((d) => (d.data.error as Error | undefined)?.message ?? null)
    }

    async logout(): Promise<void> {
        this.client
            .createBrowserLogoutFlow()
            .then((d) => this.client.updateLogoutFlow({ token: d.data.logout_token }))
            .then(() => this.#setUser(null))
    }
}

export const auth = new KratosAuthProvider()
